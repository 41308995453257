import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import "./FlightBooking";
import FlightTimeConverter from "./FlightTimeConverter";
import { Button, Tooltip } from 'antd';

export default function HititBooking() {

  //const [options, setOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [selectedCityOption, setSelectedCityOption] = useState("");
  const [Cities, setCities] = useState([]);

  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [data, setData] = useState("");


  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Function to handle when the mouse enters the Span
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  // Function to handle when the mouse leaves the Span
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  let Origin = "";
  let Destination = "";
  let duration = 0;
  let hours = 0;
  let minutes = 0;
  let img = "";
  let BookingID = 0;
  let AirlineCode = "";
  let startarrTimeMoment = new Date();
  let endarrTimeMoment = new Date();
  let arrTimeDifferenceInMinutes = 0;
  let depstops = 0;
  let retstops = 0;

  const apiUrl = process.env.REACT_APP_API_URL;


  const navigate = useNavigate();

  const location = useLocation();

  const flightData = location.state.props;
console.log('flight data',flightData)


  let adults = location.state.adults;
  let children = location.state.children;
  let infants = location.state.infants;
  let totalPax = 0;
  let departureCity = location.state.origin;
  let arrivalCity = location.state.destination;
  let logo = location.state.logo;
  let flightType = location.state.flightType;

  //console.log(flightData);


  const departureFlights = [flightData[0]]; // Initialize the departure array with the first flight
  const returnFlights = [];


  // Group flights by matching optionId
  for (let i = 1; i < flightData.length; i++) {
    const currentFlight = flightData[i];


    if (currentFlight.FlightType === "DM" || currentFlight.FlightType === "DC") {
      departureFlights.push(currentFlight);
    }
    else {
      returnFlights.push(currentFlight);
    }
  }
  // const departureBaggageInfo = departureFlights.map(
  //   flight => `${flight.weight} ${flight.unitOfMeasureCode}`
  // ).join(', ');

  // let returnBaggageInfo="";
  // if(returnFlights.length >0)
  // {
  //    returnBaggageInfo = returnFlights.map(
  //     flight => `${flight.weight} ${flight.unitOfMeasureCode}`
  //   ).join(', ');
  // }


  //Calculate Layover Time For Flights Start 
  const calculateLayoverTime = (flight1, flight2) => {
    const arrivalTime = new Date(flight1.Arr_AircraftScheduledDateTime);
    const departureTime = new Date(flight2.Dep_AircraftScheduledDateTime);

    const layoverTimeMilliseconds = departureTime - arrivalTime;
    const layoverTimeMinutes = layoverTimeMilliseconds / (1000 * 60);

    const hours = Math.floor(layoverTimeMinutes / 60);
    const remainingMinutes = layoverTimeMinutes % 60;
    let string = <p style={{ color: "white" }}>Layover: {hours}h {remainingMinutes}m - {flight1.Arr_StationName} ({flight1.Arr_IATA_LocationCode})</p>

    return string;
  };

  const layoverTimesdeparture = departureFlights.map((flight, index) => {
    if (index < departureFlights.length - 1) {
      depstops = parseInt(depstops);
      depstops++;
      depstops.toString();
      const layoverTime = calculateLayoverTime(flight, departureFlights[index + 1]);
      return layoverTime;
    }
    return null;
  });

  const layoverTimesarrival = returnFlights.map((flight, index) => {
    if (index < returnFlights.length - 1) {
      retstops = parseInt(retstops);
      retstops++;
      retstops.toString();
      const layoverTime = calculateLayoverTime(flight, returnFlights[index + 1]);
      return layoverTime;
    }
    return null;
  });
  const deptext = <span>{layoverTimesdeparture.length > 1 ? layoverTimesdeparture : "Direct"}</span>;
  const arrtext = <span>{layoverTimesarrival.length > 1 ? layoverTimesarrival : "Direct"}</span>;
  //Calculate Layover Time for Flights End 

  const departure = departureFlights[0];
  const returnflight = returnFlights[0];

  // Calculate the total flight time for departure and return flights in minutes
  // const departureFlightTime = departureFlights.reduce((total, flight) => total + Number(flight.FlightDuration), 0);
  // const returnFlightTime = returnFlights.reduce((total, flight) => total + Number(flight.FlightDuration), 0);

  // Get the time of the last index in each array
  const lastDepartureFlightTime = departureFlights[departureFlights.length - 1]?.Arr_AircraftScheduledDateTime || 0;
  const lastReturnFlightTime = returnFlights[returnFlights.length - 1]?.Arr_AircraftScheduledDateTime || 0;

  const startdepTimeMoment = moment(departure.Dep_AircraftScheduledDateTime);
  const enddepTimeMoment = moment(lastDepartureFlightTime);
  if (returnflight !== undefined) {
    startarrTimeMoment = moment(returnflight.Dep_AircraftScheduledDateTime);
    endarrTimeMoment = moment(lastReturnFlightTime);
  }

  const depTimeDifferenceInMinutes = enddepTimeMoment.diff(startdepTimeMoment, 'minutes');
  if (returnflight !== undefined) {
    arrTimeDifferenceInMinutes = endarrTimeMoment.diff(startarrTimeMoment, 'minutes');
  }


  //Departure
  let TransactionId = flightData[0].TransactionId;
  let Option_Id = flightData[0].Option_Id;
  let ArrivalDateTime = flightData[0].Arr_AircraftScheduledDateTime;
  let Dep_locationCode = flightData[0].Dep_StationName;
  let DepartureDateTime = flightData[0].Dep_AircraftScheduledDateTime;
  let Arr_locationCode = flightData[0].Arr_StationName;
  let ResBookDesigCode = flightData[0].resBookDesigCode;
  let Cabin = flightData[0].CabintypeName;
  let OfferID = flightData[0].OfferID;
  let CarrierDesigCode = flightData[0].CarrierDesigCode;
  let CurCode = flightData[0].CurCode;
  let GrandTotal = flightData[0].TotalAmount_Text;
  let ShoppingResponseRefID = flightData[0].ShoppingResponseRefID;
  //debugger
  if (flightType == "OneWay") {
    flightType = "ONEWAY"
  }
  else {
    flightType = "RETURN"
  }
  let FareComponentGroupList_Id = flightData[0].fareComponentGroupList_Id;

  //Return
  let TransactionId_r;
  let Option_Id_r;
  let ArrivalDateTime_r;
  let Dep_locationCode_r;
  let DepartureDateTime_r;
  let Arr_locationCode_r;
  let ResBookDesigCode_r;
  let Cabin_r;
  let FareComponentGroupList_Id_r;
  let departureStops = 0;
  let returnStops = 0;
  let TotalFlights;



  // if (returnflight != null)

  // for(let i=1;i<departureFlights.length;i++)
  // {
  // departureStops++;
  // }
  // for(let i=1;i<returnFlights.length;i++)
  // {
  //   returnStops++;
  // }
  if (returnflight != null) {
    TransactionId_r = returnflight.TransactionId;
    //Option_Id_r = returnflight.Option_Id;
    ArrivalDateTime_r = returnflight.Arr_AircraftScheduledDateTime;
    Dep_locationCode_r = returnflight.Dep_StationName;
    DepartureDateTime_r = returnflight.Dep_AircraftScheduledDateTime;
    Arr_locationCode_r = returnflight.Arr_StationName_StationName;
    ResBookDesigCode_r = returnflight.resBookDesigCode;
    Cabin_r = returnflight.CabintypeName;
    //FareComponentGroupList_Id_r = returnflight.fareComponentGroupList_Id;
    TotalFlights = flightData
  }

  //console.log(Dep_locationCode_r);
  // console.log(FareComponentGroupList_Id_r);
  //return;

  // console.log(flightData)
  let TotalPrice = 0;
  let TotalTaxes = 0;
  let GrandPrice = 0;
  let AdultTotalPrice = 0;
  let AdultBasePrice = 0;
  let AdultTaxes = 0;
  let ChildTotalPrice = 0;
  let ChildBasePrice = 0;
  let ChildTaxes = 0;
  let InfantTotalPrice = 0;
  let InfantBasePrice = 0;
  let InfantTaxes = 0;
  var resp = "";
 let Psa = "" ;
 let original ="";

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [grandPrice, setGrandPrice] = useState(0);
const [psa , setpsa] = useState('')
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");

  const [returnorigin, setReturnOrigin] = useState("");
  const [returndestination, setReturnDestination] = useState("");

  const [jazzFormFields, setJazzFormFields] = useState({ key1: "", key2: "" });

  const [inputFields, setInputFields] = useState([{}]);
  const [inputchildFields, setInputchildFields] = useState([{}]);
  const [inputinfantFields, setInputinfantFields] = useState([{}]);

  const [loading, setLoading] = useState(false);

  const url = apiUrl + "/api/ndc";

  const [formValue, setformValue] = React.useState({
    firstName: "",
    lastName: "",
    TransactionId: TransactionId,
    country_0: "",
    email_0: "",
    address_0: "",
    MobileNO_0: "",
    LandlineNo: "",
    bookingTitle: "",
    Gender_0: "",
    document_0: "",
    documenttype_0: "",
    expiry_0: "",
    nationality_0: "",
    dob_0: "",
    title_0: "",
    Supplier: "",
    Adults: adults,
    Children: children,
    Infants: infants,
    BookingID: "",
    type: "",
    count: "",
    from: "",
    to: "",
    start: "",
    end: "",
    FlightNumber: "",
    serviceclass0: "",
    stops0: "",
    total0: "",
    amount0: "",
    taxes0: "",
    currency0: "",
    totaltime0: "",
    totaltime1: "",
    stops1: "",
    AdultBasePrice: 0,
    AdultTotalPrice: 0,
    AdultTaxes: 0,
    ChildTotalPrice: 0,
    ChildBasePrice: 0,
    ChildTaxes: ChildTaxes,
    InfantTotalPrice: InfantTotalPrice,
    InfantTaxes: InfantTaxes,
    CarrierDesigCode: CarrierDesigCode,
    Option_Id: Option_Id,
    Cabin: Cabin,
    OfferID: OfferID,
    CurCode: CurCode,
    GrandTotal: GrandTotal,
    ShoppingResponseRefID: ShoppingResponseRefID,
    Arr_AircraftScheduledDateTime: ArrivalDateTime,
    Dep_locationCode: Dep_locationCode,
    Dep_AircraftScheduledDateTime: DepartureDateTime,
    Arr_locationCode: Arr_locationCode,
    ResBookDesigCode: ResBookDesigCode,
    // FareComponentGroupList_Id: FareComponentGroupList_Id,
    // Option_Id_r: Option_Id_r,
    // Cabin_r: Cabin_r,
    Arr_AircraftScheduledDateTime_r: ArrivalDateTime_r,
    Dep_locationCode_r: Dep_locationCode_r,
    Dep_AircraftScheduledDateTime_r: DepartureDateTime_r,
    Arr_locationCode_r: Arr_locationCode_r,
    ResBookDesigCode_r: ResBookDesigCode_r,
    // FareComponentGroupList_Id_r: FareComponentGroupList_Id_r,
    flightType: flightType,
    OriginalCost: flightData[0].OriginalCost,
    TotalFlights: flightData,
   // userid: '-1',
  });
  const newObject = flightData.map((object) => {
    return {
      ...object,
      adults: adults,
      children: children,
      infants: infants,
    };
  });

  /* console.log(newObject);
return; */

   console.log(formValue);

  async function fetchData() {
    const instance = axios.create({
      baseURL: url,
      timeout: 100000,
      headers: { "X-Custom-Header": "foobar" },
    });

    setDisableSubmitBtn(true);
    instance
      .post("/Booking", newObject)
      .then((response) => {
        resp = response.data;
        setDisableSubmitBtn(false);

      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchOriginName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/citynamebyid?term=" + 210
      )
      .then((response) => {
        const returnData = response.data;

        setTimeout(() => {
          setOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }



  async function fetchOriginName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        departureCity
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchDestinationName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        arrivalCity
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setDestination(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchReturnOriginName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        arrivalCity
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setReturnOrigin(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchReturnDestinationName() {
    await axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        departureCity
      )
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //console.log(response.data);
        setTimeout(() => {
          setReturnDestination(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchCountries() {
    await axios
      .get(apiUrl + "/api/FlightSearch/countries")
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        // console.log(response.data);
        setTimeout(() => {
          setCountries(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchCities(c) {
    await axios
      .get(apiUrl + "/api/FlightSearch/cities?term=" + c)
      .then((response) => {
        const returnData = response.data;
        //retData= response.data;
        //  console.log(response.data);
        setTimeout(() => {
          setCities(response.data);
        }, 2000);
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    //fetchData();
    window.scrollTo(0, 0);
    Amounts();

    //debugger
    if (adults > 1) {
      adults = adults - 1;
      addNewFields(adults);
    }
    if (children > 0) {
      addNewFieldsChildren(children);
    }
    if (infants > 0) {
      addNewFieldsInfants(infants);
    }

    fetchOriginName();
    fetchDestinationName();
    fetchReturnOriginName();
    fetchReturnDestinationName();
    fetchCountries();
  }, []);

  const addNewFields = (num) => {
    let newFields = [...inputFields];

    for (let i = 0; i < num; i++) {
      newFields.push({
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        address: "",
        MobileNO: "",
        LandlineNo: "",
        bookingTitle: "",
        Gender: "",
        document: "",
        documenttype: "",
        expiry: "",
        nationality: "",
        dob: "",
        title: "",
      });
    }
    
    setInputFields(newFields);
  };
  const addNewFieldsChildren = (num) => {
    let newFields = [];

    for (let i = 1; i <= num; i++) {
      newFields.push({
        firstNameC: "",
        lastNameC: "",
        countryC: "",
        emailC: "",
        addressC: "",
        MobileNOC: "",
        LandlineNoC: "",
        bookingTitleC: "",
        GenderC: "",
        documentC: "",
        documenttypeC: "",
        expiryC: "",
        nationalityC: "",
        dobC: "",
        titleC: "",
      });
    }
    
    setInputchildFields(newFields);
  };
  const addNewFieldsInfants = (num) => {
    let newFields = [];
    for (let i = 1; i <= num; i++) {
      newFields.push({
        firstNameI: "",
        lastNameI: "",
        countryI: "",
        emailI: "",
        addressI: "",
        MobileNOI: "",
        LandlineNoI: "",
        bookingTitleI: "",
        GenderI: "",
        documentI: "",
        documenttypeI: "",
        expiryI: "",
        nationalityI: "",
        dobI: "",
        titleI: "",
      });
    }
    //debugger
    setInputinfantFields(newFields);
  };
  console.log(inputchildFields);
  const [contentRef, setContentRef] = useState(null)

  const [paymentData, setPaymentData] = useState(null);

  /* function navigateToPaymentGateway()
  {
    var PaxName = formValue.firstName_0 + " " + formValue.lastName_0;
    var GrandTotal = grandPrice;

    axios
    .get(apiUrl + "/api/PaymentGateway/Payment?PaxName=" + PaxName + "&GrandTotal=" + GrandTotal)
    .then((response) => {
      //const returnData = response.data;
      //retData= response.data;
      //  console.log(response.data);
      setTimeout(() => {
        setPaymentData(response.data);
      }, 2000);
      
    })
    .catch((error) => {
      console.log(error);
    });

    console.log(paymentData);
    if (paymentData !== null)
    {

    const paymentGatewayURL = 'https://uat-merchants.niftepay.pk/CustomerPortal/transactionmanagement/merchantform';
    
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = paymentGatewayURL;

    // Create a new FormData object
     const formData = new FormData();
    // Add your form data key-value pairs
    //formData.append('param1', 'value1');
    //formData.append('param2', 'value2'); 

    for (const key in formData) {
      if (paymentData.hasOwnProperty(key)) {
        formData.append(key, formData[key]);
      }
    }

    // Append the FormData to the form
    for (let [key, value] of formData.entries()) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }

    // Append the form to the document body and submit it
    document.body.appendChild(form);
    form.submit();
  }
  } */

  function navigateToPaymentGateway() {
    const jsonData = JSON.stringify(formValue);
    navigate('/Payment', { state: { props: grandPrice } });

  }

  function postData() {
    //console.log(flightData[0].Option_Id);

    const instance = axios.create({
      baseURL: url,
      timeout: 10000,
      headers: { "X-Custom-Header": "foobar" },
    });

    if (formValue.firstName_0 != undefined) {
      if (formValue.firstName_0.length < 1) {
        alert("Please Provide First Name.");
        return;
      }
    } else {
      alert("Please Provide First Name.");
      return;
    }

    if (formValue.lastName_0 != undefined) {
      if (formValue.lastName_0.length < 1) {
        alert("Please Provide Last Name.");
        return;
      }
    } else {
      alert("Please Provide Last Name.");
      return;
    }

    if (formValue.dob_0 != undefined) {
      if (formValue.dob_0.length < 10) {
        alert("Please provide valid date of birth.");
        return;
      }
    } else {
      alert("Please provide valid date of birth.");
      return;
    }

    if (formValue.MobileNO_0 !== undefined) {
      if (formValue.MobileNO_0.length !== 11) {
        alert("Please provide a valid 11-digit mobile number.");
        return;
      }
    } else {
      alert("Please provide a valid mobile number.");
      return;
    }


    if (formValue.document_0 != undefined) {
      if (formValue.document_0.length < 1) {
        alert("Please provide document number.");
        return;
      }
    } else {
      alert("Please provide document number.");
      return;
    }

    if (formValue.expiry_0 != undefined) {
      if (formValue.expiry_0.length < 10) {
        alert("Please provide provide valid document expiry date.");
        return;
      }
    } else {
      alert("Please provide provide valid document expiry date.");
      return;
    }
    
    const updatedFormValue = { ...formValue };

    // Loop through the keys of formValue
    for (const key in updatedFormValue) {
      if (Object.hasOwnProperty.call(updatedFormValue, key)) {
        // Check if the key ends with "MobileNO_X" (replace X with the appropriate index)
        if (/MobileNO_\d+$/.test(key) || /MobileNOC_\d+$/.test(key) || /MobileNOI_\d+$/.test(key)) {
          // Extract the last 10 digits from the value
          const mobileNumber = updatedFormValue[key].slice(-10);
          // Update the value in the updatedFormValue object
          updatedFormValue[key] = mobileNumber;
        }
      }
    }

    const jsonData = JSON.stringify(updatedFormValue);
    console.log('json isLoading', jsonData);
    setIsLoading(true);
    axios
      .post(url + "/hititbooking", jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        if (response.data !== "Cannot perform runtime binding on a null reference") {
          navigate('/thankyouhitit', { state: { props: response.data } });
        }
        else {
          console.log("Booking Not Successfull ");
        }
      })
      .catch((error) => console.error(error));
  }

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleCountryChange = (event) => {
    //  console.log(event.target.value);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
    setSelectedOption(event.target.value);
    //if (event.target.name)
    fetchCities(event.target.value);
  };

  const handleSubmit = async () => {
    // store the states in the form data

    setformValue({
      ...formValue,
      transactionId: data,
    });

    const loginFormData = new FormData();

    // console.log(data);
    // console.log(formValue);

    try {
      // make axios post request
      const response = await axios({
        method: "post",
        url: url + "/SaveBooking",
        data: loginFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const res = response.data;

      navigateToPaymentGateway(res);

    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    //console.log(e);
    //  let optionID = e;

    // const filteredArray = data.filter(element => element.Option_Id === optionID);
    // navigate('/HititBooking',{state:{props: filteredArray}});

    postData();
  };

  const removeFirstTwoCharactersSlice = (str) => {
    const removedString = str.slice(2); // Remove the first two characters using slice method

    return removedString;
  };

  const Amounts = async () => {

    AdultTotalPrice = removeLetters(flightData[0].EquivAmount_Text);

    AdultBasePrice = removeLetters(flightData[0].EquivAmount_Text);
    // setformValue({...formValue,['AdultBasePrice1']: AdultBasePrice});
    AdultTaxes = removeLetters(flightData[0].Tax);
    
    Psa = removeLetters(flightData[0].OriginalCost);

    TotalPrice =
      parseInt(flightData[0].TotalAmount_Text) -
      parseInt(flightData[0].Tax)
    // console.log(AdultTotalPrice);
    TotalTaxes =
      parseInt(AdultTaxes)
 
    GrandPrice = TotalPrice + TotalTaxes
  original = (Psa-GrandPrice).toFixed(0);

    setformValue({
      ...formValue,
      ChildTotalPrice: flightData[0].Child_TotalPrice_Id,
      ChildTaxes: flightData[0].EquivAmount_Text,
      InfantTotalPrice: flightData[0].Inf_TotalPrice_Id,
      AdultTotalPrice: AdultTotalPrice,
      AdultBasePrice: AdultBasePrice,
      AdultTaxes: AdultTaxes,
      transactionId: resp,
    });
    // console.log(TotalTaxes);
    //  console.log(GrandPrice);

    setGrandPrice(GrandPrice);
    setTotalPrice(TotalPrice);
    setTotalTaxes(TotalTaxes);
   setpsa(original)
  };

  //console.log(AdultTotalPrice);

  function removeLetters(str) {
    return str.replace(/[a-z]/gi, "");
  }

  return (
    <>
      <MainMenu></MainMenu>
      {isLoading && <div className="loader"></div>}
      {!isLoading && (
        <div className="row mt-5 " style={{marginLeft:'auto',marginRight:'auto'}}>

          <br></br>

          <div className="container">
            <br></br>
            <form
              className="lg-booking-form"
              id="frm_booking"
              name="frm_booking"
              method="post"
            >
              <Grid container spacing={5}>
                <Grid xs={12} sm={6} md={8}>
                  <div className="row">
                    <div className="rcorners22">
                      <h3>Contact Details</h3>
                    </div>
                  </div>
                  <div className="rcorners2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mobile No.</label>
                          <input
                            type="tel"
                            onChange={handleChange}
                            className="form-control"
                            id="MobileNO_0"
                            name="MobileNO_0"
                            maxLength={11}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="email"
                            onChange={handleChange}
                            className="form-control"
                            id="email_0"
                            name="email_0"

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "60px" }}>
                    <div className="rcorners22">
                      <h3>Personal Information</h3>
                    </div>
                  </div>
                  <div className="rcorners2">
                    <div className="row">
                      <div className="lg-booking-form-heading col-12">
                        <span className="text-left" style={{ backgroundColor: "#8b5f16", borderRadius: "0px", width: "100px" }}> {"Adults"}</span>
                        <span>1</span>
                      </div>

                      <div className="personal-info col-12 col-md-12">
                        <div className="row">

                          <div className="col-6 col-md-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                onChange={handleChange}
                                id="firstName_0"
                                name="firstName_0"

                              />
                            </div>
                          </div>
                          <div className="col-6 col-md-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                onChange={handleChange}
                                id="lastName_0"
                                name="lastName_0"

                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 col-md-6">
                            <div className="form-group">
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                onChange={handleChange}
                                required="required"
                                className="form-control dpd3"
                                id="dob_0"
                                name="dob_0"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Passport Issuing Country</label>
                              <select
                                className="form-control"
                                onChange={handleChange}
                                id="nationality_0"
                                name="nationality_0"
                              >
                                {countries.map((option) => (
                                  <option key={option.Id} value={option.Value}>
                                    {option.Value}
                                  </option>
                                ))}
                              </select>
                              <span>
                                <i className="fa fa-angle-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">


                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender</label>
                              <select
                                className="form-control"
                                onChange={handleChange}
                                id="Gender_0"
                                name="Gender_0"
                              >
                                <option defaultValue="-1">Select</option>
                                <option selected value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                              </select>
                              <span>
                                <i className="fa fa-angle-down"></i>
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Document Type</label>
                              <select
                                className="form-control"
                                id="documenttype_0"
                                name="documenttype_0"
                              >
                                <option defaultValue="-1">Select</option>
                                <option selected value="CNIC">CNIC</option>
                                <option value="Passport">Passport</option>
                              </select>
                              <span>
                                <i className="fa fa-angle-down"></i>
                              </span>
                            </div>
                          </div>


                        </div>

                        <div className="row" style={{ display: "none" }}>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Mobile No.</label>
                              <input
                                type="email"
                                onChange={handleChange}
                                className="form-control"
                                id="MobileN"
                                name="MobileN"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Landline Number</label>
                              <input
                                type="text"
                                onChange={handleChange}
                                className="form-control"
                                id="LandlineNo_0"
                                name="LandlineNo_0"
                              />
                            </div>
                          </div>
                        </div>



                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>document</label>
                              <input
                                type="text"
                                onChange={handleChange}
                                className="form-control"
                                rows="2"
                                id="document_0"
                                name="document_0"

                              ></input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Document Expiry</label>
                              <input
                                type="date"
                                onChange={handleChange}
                                className="form-control"
                                rows="2"
                                id="expiry_0"
                                name="expiry_0"

                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      {inputFields.map((input, index) => {
                        if (index > 0) {
                          return (
                            <div id={index} className=" col-12">
                              <div className="lg-booking-form-heading">
                                <span>{index + 1}</span>
                              </div>

                              <div className="personal-info col-12 col-md-12">
                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Title</label>
                                      <select
                                        className="form-control"
                                        id={"title_" + index}
                                        name={"title_" + index}
                                      >
                                        <option defaultValue="-1">Select</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mistress">Mistress</option>
                                        <option value="Master">Master</option>
                                      </select>
                                      <span>
                                        <i className="fa fa-angle-down"></i>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>First Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        id={"firstName_" + index}
                                        name={"firstName_" + index}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Last Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        id={"lastName_" + index}
                                        name={"lastName_" + index}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Date of Birth</label>
                                      <input
                                        type="date"
                                        onChange={handleChange}
                                        className="form-control dpd3"
                                        id={"dob_" + index}
                                        name={"dob_" + index}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Passport Issuing Country</label>
                                      <select
                                        className="form-control"
                                        id={"nationality_" + index}
                                        onChange={handleChange}
                                        name={"nationality_" + index}
                                      >
                                        <option defaultValue="-1">Select</option>
                                      </select>
                                      <span>
                                        <i className="fa fa-angle-down"></i>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Email Address</label>
                                      <input
                                        type="email"
                                        onChange={handleChange}
                                        className="form-control"
                                        id={"email_" + index}
                                        name={"email_" + index}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Mobile No.</label>
                                      <input
                                        type="email"
                                        onChange={handleChange}
                                        className="form-control"
                                        id={"MobileNO_" + index}
                                        name={"MobileNO_" + index}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Gender</label>
                                      <select
                                        className="form-control"
                                        onChange={handleChange}
                                        id={"Gender_" + index}
                                        name={"Gender_" + index}
                                      >
                                        <option defaultValue="-1">Select</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                        <option value="O">Other</option>
                                      </select>
                                      <span>
                                        <i className="fa fa-angle-down"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                
                                <div className="row">
                               
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>document</label>
                                      <input
                                        type="text"
                                        onChange={handleChange}
                                        className="form-control"
                                        rows="2"
                                        id={"document_" + index}
                                        name={"document_" + index}
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Document Type</label>
                                      <select
                                        className="form-control"
                                        id={"documenttype_" + index}
                                        name={"documenttype_" + index}
                                        onChange={handleChange}
                                      >
                                        <option defaultValue="-1">Select</option>
                                        <option value="CNIC">CNIC</option>
                                        <option value="Passport">Passport</option>
                                      </select>
                                      <span>
                                        <i className="fa fa-angle-down"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <div className="form-group">
                                      <label>Document Expiry</label>
                                      <input
                                        type="date"
                                        onChange={handleChange}
                                        className="form-control"
                                        rows="2"
                                        id={"expiry_" + index}
                                        name={"expiry_" + index}
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}


                      {inputchildFields[0] && Object.keys(inputchildFields[0]).length > 0 ? inputchildFields.map((input, index) => {
                        let index1 = index + 1;
                        return (
                          <div id={index} className=" col-12">
                            <div className="lg-booking-form-heading">
                              <span className="text-left" style={{ backgroundColor: "#8b5f16", borderRadius: "0px", width: "100px" }}> {"Children"}</span>
                              <span>{index + 1}</span>


                            </div>

                            <div className="personal-info col-12 col-md-12">
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Title</label>
                                    <select
                                      className="form-control"
                                      id={"titleC_" + index1}
                                      name={"titleC_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Ms">Ms.</option>
                                      <option value="Miss">Miss</option>
                                      <option value="Mistress">Mistress</option>
                                      <option value="Master">Master</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"firstNameC_" + index1}
                                      name={"firstNameC_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"lastNameC_" + index1}
                                      name={"lastNameC_" + index1}
                                    />
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Date of Birth</label>
                                    <input
                                      type="date"
                                      onChange={handleChange}
                                      className="form-control dpd3"
                                      id={"dobC_" + index1}
                                      name={"dobC_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Passport Issuing Country</label>
                                    <select
                                      className="form-control"
                                      id={"nationalityC_" + index1}
                                      onChange={handleChange}
                                      name={"nationalityC_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                      type="email"
                                      onChange={handleChange}
                                      className="form-control"
                                      id={"emailC_" + index1}
                                      name={"emailC_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Mobile No.</label>
                                    <input
                                      type="email"
                                      onChange={handleChange}
                                      className="form-control"
                                      id={"MobileNOC_" + index1}
                                      name={"MobileNOC_" + index1}
                                    />
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Gender</label>
                                    <select
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"GenderC_" + index1}
                                      name={"GenderC_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="M">Male</option>
                                      <option value="F">Female</option>
                                      <option value="O">Other</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                              
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>document</label>
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      className="form-control"
                                      rows="2"
                                      id={"documentC_" + index1}
                                      name={"documentC_" + index1}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Document Type</label>
                                    <select
                                      className="form-control"
                                      id={"documenttypeC_" + index1}
                                      name={"documenttypeC_" + index1}
                                      onChange={handleChange}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="CNIC">CNIC</option>
                                      <option value="Passport">Passport</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Document Expiry</label>
                                    <input
                                      type="date"
                                      onChange={handleChange}
                                      className="form-control"
                                      rows="2"
                                      id={"expiryC_" + index1}
                                      name={"expiryC_" + index1}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );

                      }) : <></>}

                      {inputinfantFields[0] && Object.keys(inputinfantFields[0]).length > 0 ? inputinfantFields.map((input, index) => {
                        let index1 = index + 1;
                        return (
                          <div id={index} className=" col-12">
                            <div className="lg-booking-form-heading">
                              <span className="text-left" style={{ backgroundColor: "#8b5f16", borderRadius: "0px", width: "100px" }}> {"Infants"}</span>
                              <span>{index + 1}</span>
                            </div>

                            <div className="personal-info col-12 col-md-12">
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Title</label>
                                    <select
                                      className="form-control"
                                      id={"titleI_" + index1}
                                      name={"titleI_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Ms">Ms.</option>
                                      <option value="Miss">Miss</option>
                                      <option value="Mistress">Mistress</option>
                                      <option value="Master">Master</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"firstNameI_" + index1}
                                      name={"firstNameI_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"lastNameI_" + index1}
                                      name={"lastNameI_" + index1}
                                    />
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Date of Birth</label>
                                    <input
                                      type="date"
                                      onChange={handleChange}
                                      className="form-control dpd3"
                                      id={"dobI_" + index1}
                                      name={"dobI_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Passport Issuing Country</label>
                                    <select
                                      className="form-control"
                                      id={"nationalityI_" + index1}
                                      onChange={handleChange}
                                      name={"nationalityI_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                      type="email"
                                      onChange={handleChange}
                                      className="form-control"
                                      id={"emailI_" + index1}
                                      name={"emailI_" + index1}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Mobile No.</label>
                                    <input
                                      type="email"
                                      onChange={handleChange}
                                      className="form-control"
                                      id={"MobileNOI_" + index1}
                                      name={"MobileNOI_" + index1}
                                    />
                                  </div>
                                </div>

                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Gender</label>
                                    <select
                                      className="form-control"
                                      onChange={handleChange}
                                      id={"GenderI_" + index1}
                                      name={"GenderI_" + index1}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="M">Male</option>
                                      <option value="F">Female</option>
                                      <option value="O">Other</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>document</label>
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      className="form-control"
                                      rows="2"
                                      id={"documentI_" + index1}
                                      name={"documentI_" + index1}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Document Type</label>
                                    <select
                                      className="form-control"
                                      id={"documenttypeI_" + index1}
                                      name={"documenttypeI_" + index1}
                                      onChange={handleChange}
                                    >
                                      <option defaultValue="-1">Select</option>
                                      <option value="CNIC">CNIC</option>
                                      <option value="Passport">Passport</option>
                                    </select>
                                    <span>
                                      <i className="fa fa-angle-down"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6 col-md-6">
                                  <div className="form-group">
                                    <label>Document Expiry</label>
                                    <input
                                      type="date"
                                      onChange={handleChange}
                                      className="form-control"
                                      rows="2"
                                      id={"expiryI_" + index1}
                                      name={"expiryI_" + index1}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );

                      }) : <></>}
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" id="chk_agree" name="chk_agree" />{" "}
                          By continuing, you are agree to the{" "}
                          <Link href="#">Terms and Conditions.</Link>
                        </label>
                      </div>
                      <div className="col-md-12 text-center" id="result_msg"></div>
                      {disableSubmitBtn ? (
                        <>Loading..</>
                      ) : (
                        <>
                          <a onClick={handleClick} className="btn btn-orange">
                            Confirm Booking
                          </a>
                        </>
                      )}

                    </div>
                    <div>
                      <img
                        src={"images/payfast.png"}
                        width={150} height={100}
                        style={{marginLeft:'50px',marginTop:'20px'}}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                  <div className="row">
                    <div className="rcorners22">
                      <h3>Price Summary</h3>
                    </div>
                  </div>
                  <div className="rcornersflight">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-12">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Price</td>
                                <td>PKR {totalPrice}</td>
                              </tr>
                              <tr>
                                <td>Tax</td>
                                <td>PKR {totalTaxes}</td>
                              </tr>
                              {/* <tr>
                                <td>PSA</td>
                                <td>PKR {psa}</td>
                              </tr> */}
                              <tr>
                                <td>Price you pay</td>
                                <td>PKR {grandPrice}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="rcorners22">
                      <h3>Flight Details</h3>
                    </div>
                  </div>
                  <div className="rcornersflight" style={{ overflow: "hidden" }}>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-12">
                        <div style={{ display: "none" }}>
                          {(duration = flightData[0].FlightTime)}
                          {(hours = Math.floor(duration / 60))}
                          {(minutes = duration % 60)}
                          {(img = flightData[0].Carrier + ".png")}
                        </div>
                        {/* Departure Data Div */}
                        {
                          departure ? <div className="shadow" style={{ boxShadow: "#00000033" }}>
                            <div className="mt-5">
                              <div className="" style={{ height: "30px", backgroundColor: "#f0f0f0", overflow: "hidden" }}>
                                <div className="row w-100 h-100 d-flex">
                                  <div className="col-6">
                                    Departure
                                  </div>
                                  <div className=" col-6">
                                    {departure.Dep_AircraftScheduledDateTime ? moment(departure.Dep_AircraftScheduledDateTime).format(
                                      "DD MMMM YYYY"
                                    ) : ""}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="">
                                <img
                                  src={logo}
                                  className="img-fluid" width={40} height={30}
                                />
                              </div>
                              <div className="ml-3">
                                {"Pakistan Airlines"}
                              </div>
                              <div className="ml-3">
                                {departure.CarrierDesigCode}-{departure.OperatingCarrierFlightNumberText}
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                                {departure.Dep_AircraftScheduledDateTime ? moment(departure.Dep_AircraftScheduledDateTime).utcOffset("+05:00").format("hh:mm A") : ""}
                              </div>
                              <div className="ml-3 pr-5 pl-5" style={{ backgroundColor: "#f0f0f0", borderRadius: "20px" }} visible={tooltipVisible}>
                                <FlightTimeConverter minutes={depTimeDifferenceInMinutes} />



                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                                {lastDepartureFlightTime ? moment(lastDepartureFlightTime).utcOffset("+05:00").format("hh:mm A") : ""}
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                {origin ? origin.Name : ""} {`(${departureCity})`}
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>

                                <Tooltip placement="top" title={deptext} style={{ cursor: "pointer" }} >
                                  {<span style={{ color: "#faa61a", textDecoration: "underline" }}>{depstops} </span>}<span style={{ color: "#faa61a", textDecoration: "underline" }}> Stops</span>


                                  {/* <Button>Top</Button> */}
                                  <span onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ cursor: 'pointer' }}>
                                  </span>
                                </Tooltip>
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                {destination ? destination.Name : ""} {`(${arrivalCity})`}
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                Beggage:
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                <span>Total</span><span style={{ fontWeight: "400" }}> {departure.weight} {departure.unitOfMeasureCode}</span>
                              </div>

                            </div>

                            <hr></hr>


                          </div> : ""
                        }

                        {/* Arrival Data Div */}
                        {returnflight ?
                          <div className="shadow" style={{ boxShadow: "#00000033" }}>
                            <div className="mt-5">
                              <div className="" style={{ height: "30px", backgroundColor: "#f0f0f0", overflow: "hidden" }}>
                                <div className="row w-100 h-100 d-flex">
                                  <div className="col-6">
                                    Departure
                                  </div>
                                  <div className=" col-6">
                                    {returnflight.Dep_AircraftScheduledDateTime ? moment(returnflight.Dep_AircraftScheduledDateTime).format(
                                      "DD MMMM YYYY"
                                    ) : ""}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="">
                                <img
                                  src={logo}
                                  className="img-fluid" width={40} height={30}
                                />
                              </div>
                              <div className="ml-3">
                                {"Pakistan Airlines"}
                              </div>
                              <div className="ml-3">
                                {returnflight.CarrierDesigCode}-{returnflight.OperatingCarrierFlightNumberText}

                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                                {returnflight.Dep_AircraftScheduledDateTime ? moment(returnflight.Dep_AircraftScheduledDateTime).utcOffset("+05:00").format("hh:mm A") : ""}
                              </div>
                              <div className="ml-3 pr-5 pl-5" style={{ backgroundColor: "#f0f0f0", borderRadius: "20px" }}>
                                <FlightTimeConverter minutes={arrTimeDifferenceInMinutes} />


                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "20px", fontWeight: "400" }}>
                                {lastReturnFlightTime ? moment(lastReturnFlightTime).utcOffset("+05:00").format("hh:mm A") : ""}
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                {returnorigin ? returnorigin.Name : ""} {`(${arrivalCity})`}
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                <Tooltip placement="top" title={arrtext} >
                                  {<span style={{ color: "#faa61a", textDecoration: "underline" }}>{retstops} </span>}<span style={{ color: "#faa61a", textDecoration: "underline" }}> Stops</span>

                                  {/* <Button>Top</Button> */}
                                  <span onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ cursor: 'pointer' }}>
                                  </span>
                                </Tooltip>
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                {returndestination ? returndestination.Name : ""} {`(${departureCity})`}
                              </div>
                            </div>

                            <div className="row mt-5 ml-5">
                              <div className="" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                Beggage:
                              </div>
                              <div className="ml-3" style={{ fontFamily: "inherit", fontSize: "13px", fontWeight: "400" }}>
                                <span>Total</span><span style={{ fontWeight: "400" }}> {returnflight.weight}</span> {returnflight.unitOfMeasureCode}

                              </div>

                            </div>

                            <hr></hr>
                          </div> : ""}


                        {/* <div className="side-bar-block detail-block style1 text-center">
                     
                      <div className="detail-img text-center">
                        <Link href="#">
                          <img
                            src={"images/airlines/" + img}
                            className="img-fluid" width={120} height={100}
                          />
                        </Link>
                      </div>

                      <div className="detail-title">
                        <h4>
                          {" "}
                          <b>{origin ? origin.Name : ""}</b> to{" "}
                          <b>{destination ? destination.Name : ""}</b>{" "}
                        </h4>
                        <p>Oneway Flight</p>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>
                            <tr>
                              <td>Departure</td>
                              <td>
                                {moment(flightData.DepartureTime).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                -{" "}
                                {moment(flightData.DepartureTime).format(
                                  "HH:MM"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Arrival</td>
                              <td>
                                {moment(flightData.ArrivalTime).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                -{" "}
                                {moment(flightData.ArrivalTime).format("HH:MM")}
                              </td>
                            </tr>
                            <tr>
                              <td>Class</td>
                              <td>
                                {flightData[0].CabinClass} (
                                {flightData[0].BookingCode})
                              </td>
                            </tr>
                            <tr>
                              <td>Stops</td>
                              <td>{flightData[0].Stops}</td>
                            </tr>
                            <tr>
                              <td>Flight Duration</td>
                              <td>
                                {" "}
                                {hours} hours - {minutes} minutes
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                      </div>

                      <div className="col-12 col-md-6 col-lg-12">
                        <div className="side-bar-block support-block">
                          <h3>Need Help</h3>

                          <div className="support-contact">
                            <span>
                              <i className="fa fa-phone"></i>
                            </span>
                            <p>+92 51 111 77 88 00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      )}
      <Footer></Footer>

    </>
  );
}
