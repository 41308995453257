import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {

    const Url = "https://malikexpress.com/";
    const ImageUrl=Url+ "images/logo mex.svg";
    return (
        <>

            <a href="tel:+923111778811" class="floatp" target="_blank">
                <i class="fa fa-phone my-floatp mt-2"></i>
            </a>
            <a href="https://wa.me/+923111778811?text=Hi!%20Malik%20Express." class="float" target="_blank">
                <i class="fa fa-whatsapp my-float mt-2"></i>
            </a>


            <section id="footer" className="ftr-heading-o ftr-heading-mgn-1">
                <div id="footer-top" className="banner-padding ftr-top-grey ftr-text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-left col-md-6 col-lg-4 col-xl-4 footer-widget ftr-about">
                            <img src={ImageUrl} alt="malik express logo" />
                                <p>
                                    We are the best travel agency in Islamabad, and for a good
                                    reason. Our expert travel agents can help you find cheap
                                    flights and consultancy for the visa, hotel booking, travel
                                    insurance, Umrah packages and tour packages from Pakistan. So
                                    whether you're looking for a complete travel solution or just
                                    a little help with your travel plans, Malik Express is a
                                    perfect choice.
                                </p>
                                <ul className="social-links list-inline list-unstyled">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/malikexpress/">
                                            <span><i className="fa fa-facebook"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/malik_express">
                                            <span><i className="fa fa-twitter"></i></span>
                                        </a>
                                    </li>

                                    <li className="list-inline-item">
                                        <a href="https://www.pinterest.com/malikexpress/">
                                            <span><i className="fa fa-pinterest-p"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D">
                                            <span><i className="fa fa-instagram"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/in/malik-express-805888138/">
                                            <span><i className="fa fa-linkedin"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg">
                                            <span><i className="fa fa-youtube-play"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 text-left col-md-6 col-lg-3 col-xl-3 footer-widget ftr-contact">
                                <h3 className="footer-heading">Contact Us</h3>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="https://g.page/malik-express-travel-agent?share">
                                            <span><i className="fa fa-map-marker"></i></span>Office no.2,
                                            Blue Area, Islamabad
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:051-111-778-800">
                                            <span><i className="fa fa-phone"></i></span>051-111-778-800
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+923111778811">
                                            <span><i className="fa fa-phone"></i></span>+92 311 1778811
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:info@starhotel.com">
                                            <span>
                                                <i className="fa fa-envelope"></i>info@malikexpress.com
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-12 text-left col-md-6 col-lg-2 col-xl-2 footer-widget ftr-links">
                                <h3 className="footer-heading">Explore</h3>
                                <ul className="list-unstyled">
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">Flight</a></li>
                                    <li><a href="#">Hotel</a></li>
                                    <li><a href="#">Tours</a></li>
                                    <li><a href="#">Cruise</a></li>
                                    <li><a href="#">Cars</a></li>
                                </ul>
                            </div>
                            <div className="col-12 text-left col-md-6 col-lg-3 col-xl-3 footer-widget ftr-links ftr-pad-left">
                                <h3 className="footer-heading">Payment Methods</h3>
                                <ul className="list-unstyled">
                                    {/* <li><img src="../images/payment/IATA.png" width="30" height="25" /></li> */}
                                    <li><img src="../images/payment/HBL.jpg" width="30" height="25" alt='payment'/></li>
                                    <li><img src="../images/payment/cash.png" width="30" height="25"alt='payment' /></li>
                                    <li><img data-v-b5e8af86="" src="../images/payment//icon-footer-mastercard.afdd5b7f.svg" width="30" height="25" alt='payment'/></li>
                                    <li><img data-v-b5e8af86="" src="../images/payment/icon-footer-visa.0cc68109.svg" width="30" height="25" alt='payment'/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="footer-bottom" className="ftr-bot-black">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6" id="copyright">
                                <p>
                                    © 2024 <a href="https://growbiztech.com/">GrowBiz Tech (Pvt) Ltd</a>. All rights
                                    reserved.
                                </p>
                            </div>

                            <div className="col-12 col-md-6 col-lg-6 col-xl-6" id="terms">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                        <Link className="nav-link" to="/terms-and-condition" style={{ color: "#ffc107", fontWeight: 'normal' }}>Terms & Conditions</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="nav-link" to="/privacy-policy" style={{ color: "#ffc107", fontWeight: 'normal' }}>Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;