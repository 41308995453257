
import React from 'react';
import './UmrahPackages.css';
import UmrahEnquiryForm from './UmrahEnquiryForm';


const UmrahPackages= ({ sevenTitleRef,tenTitleRef,fifteenTitleRef,twentyOneTitleRef, twentyEightTitleRef, enquiryFormRef}) => {
    const scrollToSevenForm = () => {
        if (sevenTitleRef && sevenTitleRef.current) {
            sevenTitleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
  const scrollToTenForm = () => {
    if(tenTitleRef && tenTitleRef.current) {
        tenTitleRef.current.scrollIntoView({ behavior: 'smooth'});
    }
  }
  const scrollToFifteenForm = () => {
    if(fifteenTitleRef && fifteenTitleRef.current) {
        fifteenTitleRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }
  const scrollToTwentyOneForm = () => {
  if(twentyOneTitleRef && twentyOneTitleRef.current) {
    twentyOneTitleRef.current.scrollIntoView({behavior: "smooth"})
  }
  }
  const scrollToTwentyEightForm = () => {
    if(twentyEightTitleRef && twentyEightTitleRef.current) {
        twentyEightTitleRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }
  const scrollToEnquiryForm = () => {
    if(enquiryFormRef && enquiryFormRef.current) {
        enquiryFormRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }
    return (
        <div className="umh-duration-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="umh-heading">
                            <h2> Explore Our Flexible Umrah Packages for Various Durations</h2>
                            <p>At Malik Express Travel & Tours, we understand that every pilgrim's journey is unique. That's why we offer a range of Umrah packages tailored to fit different schedules and needs. Whether you're looking for a quick spiritual getaway or a more extended stay to immerse yourself in holy cities, our packages provide the perfect balance of comfort, convenience, and affordability. Choose the duration that suits you best and embark on a memorable pilgrimage with us.</p>
                        </div>
                       
                        <UmrahEnquiryForm/>
                        
                        
                        <ul className="umh-duration-list">
                            <li onClick={scrollToSevenForm}><a>7<em>Days</em></a></li>
                            <li onClick={scrollToTenForm}><a >10<em>Days</em></a></li>
                            <li onClick={scrollToFifteenForm}><a >15<em>Days</em></a></li>
                            <li onClick={scrollToTwentyOneForm}><a>21<em>Days</em></a></li>
                            <li onClick={scrollToTwentyEightForm}><a>28<em>Days</em></a></li>
                            <li onClick={scrollToEnquiryForm}>
                                <a>
                                    <span>
                                        Make My
                                        Custom
                                        Package
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
             
            </div>
          
        </div>
    );
}

export default UmrahPackages;
