import React, { useState, useEffect, forwardRef } from 'react';
import './EnquiryForm.css';
import PersonIcon from '@mui/icons-material/Person';
import { DatePicker } from 'react-rainbow-components';
import { useNavigate } from "react-router-dom";
import { Radio, Space, Select, message } from 'antd';
import { format } from 'date-fns';
import axios from "axios";
import Alert from '@mui/material/Alert';

const EnquiryForm = forwardRef((props,ref) => {
  const [selectedCity, setSelectedCity] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [countries, setCountries] = useState([]);
  
  const [Startdate, setStartDate] = useState(format(new Date(), 'yyyy/MM/dd'));
  const [EndDate, setEndDate] = useState(format(new Date(Startdate), 'yyyy/MM/dd'));
  const [UmrahStartdate, UmrahsetStartDate] = useState(format(new Date(), 'yyyy/MM/dd'));
  const [UmrahEnddate, UmrahsetEndDate] = useState(format(new Date(Startdate), 'yyyy/MM/dd'));
  const [selectedUmrahCity, setselectedUmrahCity] = useState('Makkah');
  const [activeTab, setActiveTab] = useState('umrah');
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    DepartureAirport: '',
    DepartureDate: '',
    Adults: '1',
    Child: '0',
    Infant: '0',
    leadPassengerName: '',
    Distance: '',
    contactNo: '',
    nightsInMakkah:'0',
    nightsInMadinah:'0'
    
    
  });
  console.log('formdata',formData)
  const navigate = useNavigate();

  const UmrahSetStartDate = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    UmrahsetStartDate(formattedDate);
    setFormData(prevState => ({
      ...prevState,
      ['DepartureDate']: formattedDate
    }));
  };

  const UmrahSetEndDate = (date) => {
    UmrahsetEndDate(format(date, 'yyyy/MM/dd'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log('formdata', formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  async function fetchCountries() {
    await axios
        .get(apiUrl + "/api/flightsearch/pakcities")
        .then((response) => {
            const returnData = response.data;
            setTimeout(() => {
                setCountries(response.data);
            }, 2000);
        })
        .catch((error) => {
            console.log(error);
        });
  }
  
  useEffect(() => {
    fetchCountries();
  }, []);

  function postData() {
    setLoading(true);
    setAlertMessage('');
    setErrorMessage('')
    
    if (formData.leadPassengerName != undefined) {
      if (formData.leadPassengerName.length < 1) {
          alert("Please Provide Passenger Name");
          setLoading(false);
          return;
      }
    } else {
      alert("Please provide Passenger Name");
      setLoading(false);
      return;
    }



    if (formData.contactNo != undefined) {
      if (formData.contactNo.length < 1) {
          alert("Please provide Contact number");
          setLoading(false);
          return;
      }
    } else {
      alert("Please Provide Contact Number");
      setLoading(false);
      return;
    }

    const jsonData = JSON.stringify(formData);
    console.log('json is', jsonData);
    axios
        .post(apiUrl + "/api/Umrah/umrahinquiry", jsonData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            if (response.status === 200) {
                setAlertMessage('Reception will reach you as soon as possible.');
            } else {
                console.error("Unexpected response status:", response.status);
                setErrorMessage("Your provided data is not valid plz try again", response.status)

            }
            setLoading(false);
        })
        .catch((error) => {
            console.error('error during umrah', error);
            setErrorMessage("Something went wrong plz try again", error)

            setLoading(false);
        });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postData();
  };

  return (
    <>
    
      <div ref={ref} className='enquiry-main'>
        <div className="enquiry-container">
          <div className="tab-header">
            <a
              className={`tab ${activeTab === 'umrah' ? 'active' : ''}`}
              href='/umrahform'
            >
              Search Hotel
            </a>
          </div>

          <h3>UMRAH ENQUIRY</h3>

        

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-lg-2 departureAirport">
                <label>Departure City
                 
                    <select
                        // className="form-control"
                        id="DepartureAirport"
                        name="DepartureAirport"
                        className='makkahNight-slect'
                        value={formData.DepartureAirport}
                        onChange={handleChange}
                    >
                        <option value="" selected>
                            Select City
                        </option>
                        {countries.map((option) => (
                            <option key={option.Id} value={option.Value}>
                                {option.Value}
                            </option>
                        ))}
                    </select>
                 
                </label>
              </div>

              <div className="col-lg-2 depDate">
                <label>Departure Date</label>
                <DatePicker
                  className='Datepickerwidth'
                  placeholder='Start Date'
                  minDate={new Date()}
                  name="DepartureDate"
                  value={UmrahStartdate}
                  onChange={UmrahSetStartDate}
                />
              </div>
              <div className="col-lg-2 makkahNights">
                <label>Night in Makkah</label>
                <select className='makkahNight-slect ' name="nightsInMakkah" value={formData.nightsInMakkah} onChange={handleChange}>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="col-lg-2 madinaNights">
                <label>Night in Madinah</label>
                <select className='makkahNight-slect' name="nightsInMadinah" value={formData.nightsInMadinah} onChange={handleChange}>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="col-lg-3 noOfTravelers">
                <label>No. of Travellers
                  <ul className="nav nav-justified noOfTraveler">
                    <li>
                      <span className="wpcf7-form-control-wrap menu-390">
                        <select name="Adults" 
                          className="wpcf7-form-control makkahNight-slect wpcf7-select wpcf7-validates-as-required" 
                          id="Adults"
                          required=""
                          value={formData.Adults} 
                          onChange={handleChange}
                          
                        >
                          <option value="Adults">Adults</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                        </select>
                      </span>
                    </li>
                    <li>
                      <span className="wpcf7-form-control-wrap menu-944">
                        <select name="Child" 
                          id="Child" 
                          className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required  makkahNight-slect"
                          required=""
                          value={formData.Child} 
                          onChange={handleChange}
                        >
                          <option value="Child">Child</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                        </select>
                      </span>
                    </li>
                    <li>
                      <span className="wpcf7-form-control-wrap menu-257">
                        <select name="Infant"
                          id="infant" 
                          className=" makkahNight-slect wpcf7-form-control wpcf7-select wpcf7-validates-as-required" 
                          required=""
                          value={formData.Infant} 
                          onChange={handleChange}
                        >
                          <option value="Infant">Infant</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                        </select>
                      </span>
                    </li>
                  </ul>
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-lg-2 hotelType">
                <label>
                  Hotel Type<br />
                  <span className="wpcf7-form-control-wrap menu-412">
                    <select
                      name="hotelType"
                      id="hotelType"
                      className="wpcf7-form-control makkahNight-slect wpcf7-select wpcf7-validates-as-required "
                      required
                      value={formData.hotelType}
                      onChange={handleChange}
                      style={{ width: "146%" }}
                    >
                      <option value="Any Star">Any Type</option>
                      <option value="Sharing">Sharing</option>
                      <option value="Quint">Quint </option>
                      <option value="Quad">Quad</option>
                      <option value="Triple">Triple </option>
                      <option value="Double">Double  </option>
                    </select>
                  </span>
                </label>
              </div>
              <div className="col-lg-2 leadPassengerName">
                <label>Your Name</label>
                <input className='madinah-makkah' style={{ padding: '8px' }} type="text" starticon={<PersonIcon />} name="leadPassengerName" placeholder="Full Name" value={formData.leadPassengerName} onChange={handleChange} />
              </div>
              <div className="col-lg-2 hotelType">
                <label>
                  Distance<br />
                  <span className="wpcf7-form-control-wrap menu-412">
                    <select
                      name="Distance"
                      id="hotelType"
                      className="wpcf7-form-control wpcf7-select makkahNight-slect wpcf7-validates-as-required "
                      required
                      value={formData.Distance}
                      onChange={handleChange}
                    >
                      <option value="Any Star">Select Distance</option>
                      <option value="SHUTTLE SERVICE">SHUTTLE SERVICE</option>
                      <option value="850-900 MTR">850-900 MTR</option>
                      <option value="700-800 MTR">700-800 MTR</option>
                      <option value="500-600 MTR">500-600 MTR</option>
                      <option value="650-700 MTR">650-700 MTR</option>
                      <option value="450-500 MTR ">450-500 MTR</option>
                      <option value="600 MTR">600 MTR</option>
                      <option value="250-300 MTR">250-300 MTR</option>
                      <option value="200-250 MTR">200-250 MTR </option>
                      <option value="350-400 MTR">350-400 MTR </option>
                      <option value="200 MTR">200 MTR </option>
                      <option value="100 MTR">100 MTR </option>
                    </select>
                  </span>
                </label>
              </div>
              <div className="col-lg-2 contactNumber">
                <label>Contact No.</label>
                <input  className='madinah-makkah' style={{ padding: '8px' }} type="tel" name="contactNo" placeholder="  +92-XXXXXXXXXX" value={formData.contactNo} onChange={handleChange} />
              </div>
              <div className="col-lg-2 btnSubmit">
                <button type="submit" 
                 className="btn btn-orange mt-6"
                  style={{ padding: '8px' }}
                  disabled={loading}
                >
                  {loading ? (
                    <span>Loading...</span>
                  ) : (
                    <>
                      
                        <>GET BEST PRICES</>
                     
                    </>
                  )}
                </button>
              </div>
           
            </div>
            {alertMessage && (
            
              <Alert variant="filled" severity="success" style={{ marginBottom: '10px' }}>
              {alertMessage}
            </Alert>
             
          )}
            {errorMessage && (
            
            <Alert variant="filled" severity="error" style={{ marginBottom: '10px' }}>
            {errorMessage}
          </Alert>
           
        )}

          </form>
        </div>
      </div>
    </>
  );
});

export default EnquiryForm;
