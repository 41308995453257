import React from 'react'
import Head from "./Head";
import { Helmet } from 'react-helmet';
import ToursSlider from "./TourSlider";
import UmrahCitySection from "./UmrahCitySection";
import UmrahPackages from "./UmrahPackages";
import EnquiryForm from "./EnquiryForm";
import Journey from "./Journey";
import TopContent from "./TopContent";
import MiddleContent from "./MiddleContent";

import MainMenu from '../Layout/MainMenu';
import VipUmrah from './VipUmrah'
import Blog from '../Blog/blog';
import Footer from '../Home/Footer';
import Testimonials from '../Home/Testimonials';
import LatestBlogs from '../Home/LatestBlogs';
import Newsletters from '../Home/Newsletters';
import Offers from '../Tours/Offers';

import ContentHomeDes from './ContentHomeDes';
import UmrahPackagesDays from './UmrahPackageDays';

const UmrahHome = () => {
  return (
    <div>
      <Helmet>
                <title >{"Customized Umrah Packages from Pakistan | Ramadan Packages"}</title>
                
                <meta name="description" content={"Malik Express offers range of customized Umrah Packages from Pakistan. Umrah Visa, Family Umrah Packages & 7 days, 10 days, 20 days, 30 days umrah packages. "} data-react-helmet="true" />
                <meta name="keywords" content={"umrah packages, customized umrah packages, umrah visa price, umrah packages from Islamabad, Ramadan umrah packages, 28 days umrah package from Pakistan today, 7 days umrah package from Pakistan, umrah packages for 7 days, umrah packages for family, umrah packages 7 days, 30 days umrah package from Pakistan, umrah economy packages, VIP umrah packages"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/umrah-packages-from-pakistan" />
            </Helmet>



            <MainMenu></MainMenu>
  
    <EnquiryForm/>
    
   <Head/>
<ToursSlider/>
<UmrahCitySection/>
{/* 
<Offers /> */}

<ContentHomeDes/>
<TopContent/>
<Journey/>
<MiddleContent/>
<VipUmrah/>
<LatestBlogs />
<Testimonials/>
<Newsletters />
<Footer/>

    </div>
  )
}

export default UmrahHome
